@import '../../../styles/propertySets.css';

.root {
  width: 100%;
}

.title {
  line-height: 1.2;
  font-size: 28px;

  @media (--viewportMedium) {
    font-size: 32px;
  }
}

.subtitle {
  font-size: 20px;
  line-height: 1.2;
  max-width: 710px;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.emailContainer {
  display: flex;

  flex-direction: column;

  @media (--viewportSmall) {
    flex-direction: row;
    align-items: center;
  }

  @media (--viewportMedium) {
    align-items: center;
  }
}

.emailInput {
  background: white;
  height: 56px;

  @media (--viewportMedium) {
    max-width: 334px;
  }
}

.button {
  color: var(--matterColorLight);
  background: var(--customYellow);
  width: 179px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  height: 56px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 700;

  &:hover {
     background-color: var(--customYellowDark);
     color: var(--matterColorLight);
   }

  @media (--viewportSmall) {
    margin-top: 40px;
  }
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.info {
  /* padding-top: 100px; */

  @media (--viewportMedium) {
    padding-top: 100px;
  }
}

.image {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.imageContainer {
  @media (--viewportMedium) {
    margin-top: 70px;
  }
}

.createCourseContainer {
  & .button {
    margin-left: 0;
  }
}

.socialButtonsWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}
