@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.heroRoot {
  width: 100%;
  height: 100%;

  & :global(.coloredContainer_-1) {
    background-position: 0 0 !important;
    transition: none !important;

    & [class*="SectionHero_heroTypeString"] {
      color: #EDCD90;
    }
    & [class*="SectionHero_heroButton"] {
      background-color: #EDCD90;
    }
  }

  & :global(.coloredContainer_0) {
    background-position: 0 100%;
    transition: background-position 1.5s ease;

    & [class*="SectionHero_heroTypeString"] {
      color: #EDCD90;
    }
    & [class*="SectionHero_heroButton"] {
      background-color: #EDCD90;
    }
  }
  & :global(.coloredContainer_1) {
    background-position: 20% 0;
    transition: background-position 1.5s ease;

    & [class*="SectionHero_heroTypeString"] {
      color: #3FAFDD;
    }
    & [class*="SectionHero_heroButton"] {
      background-color: #3FAFDD;
    }
  }
  & :global(.coloredContainer_2) {
    background-position: 40% 0;
    transition: background-position 1.5s ease;

    & [class*="SectionHero_heroTypeString"] {
      color: #B0E0F5;
    }
    & [class*="SectionHero_heroButton"] {
      background-color: #B0E0F5;
    }
  }
  & :global(.coloredContainer_3) {
    background-position: 60% 0;
    transition: background-position 1.5s ease;

    & [class*="SectionHero_heroTypeString"] {
      color: #FEEBC4;
    }
    & [class*="SectionHero_heroButton"] {
      background-color: #FEEBC4;
    }
  }
  & :global(.coloredContainer_4) {
    background-position: 80% 0;
    transition: background-position 1.5s ease;

    & [class*="SectionHero_heroTypeString"] {
      color: #E85299;
    }
    & [class*="SectionHero_heroButton"] {
      background-color: #E85299;
    }
  }
  & :global(.coloredContainer_5) {
    background-position: 100% 0;
    transition: background-position 1.5s ease;

    & [class*="SectionHero_heroTypeString"] {
      color: #4A4A4A;
    }
    & [class*="SectionHero_heroButton"] {
      background-color: #4A4A4A;
    }
  }
}

.hero {
  flex-grow: 1;
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) auto 0;
  padding: 0 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopMedium) auto 0;
  }

  @media (--viewportLarge) {
    /* max-width: 1272px; */
    max-width: 1600px;
  }

  @media (--viewportXLarge) {
    /* max-width: 1200px; */
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 55px;

  @media (--viewportLarge) {
    margin-top: 55px;
  }
}

.sectionContentLastChild {
  composes: sectionContent;
  margin-bottom: 60px;
  padding: 0;

  @media (--viewportLarge) {
    margin-bottom: 110px;
  }
}

.sectionPublishCourse {
  min-height: 559px;
  /* background: rgba(0, 104, 168, 0.15); */
  background: #D9ECFC;

  & .sectionContent {
    display: flex;
    height: 100%;
    align-items: center;
    margin-top: 0;
    width: 100%;
    padding: 36px;
  }
}

.buttonHolder {
  display: flex;
  justify-content: center;
  padding: 30px 0 30px;
}

.primaryButton {
  color: var(--matterColorLight);
  background: var(--failColor);
  height: 56px;
  padding: 0 50px;
  border-radius: var(--borderRadius);
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 300px;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
     background-color: var(--failColorDark);
     color: var(--matterColorLight);
   }
}
