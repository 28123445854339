@import '../../../styles/propertySets.css';

.description {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.descriptionItem {
  font-size: 16px;
  line-height: 24px;

  width: 100%;
  font-weight: var(--fontWeightRegular);
  margin: 0;

  &:first-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    /* width: calc(50% - 20px); */
    margin-bottom: 0;

    font-size: 20px;
    line-height: 30px;
  }

  /* @media (--viewportMLarge) {
    max-width: 562px;
  } */
}

.blue {
  color: var(--marketplaceColor);
}

.red {
  color: var(--failColor);
}

.underline {
  border-bottom: 1px solid;
  font-weight: var(--fontWeightRegular);
}
