@import '../../../styles/propertySets.css';

.root {

}

.rootTitle {
  @apply --marketplaceH2CustomFontStyles;
  border-bottom: 4px  solid var(--marketplaceColor);
  width: 100%;
  max-width: 315px;
  margin: 0 auto 65px;
  padding-bottom: 5px;
  text-align: center;
}

.adminInfo {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.underline {
  text-decoration: underline;
  font-weight: 400;
}

.adminInfoIcon {
  flex-shrink: 0;
  width: 100%;

  @media (--viewportMedium) {
    width: auto;
  }

  @media (--viewportLarge) {
    margin-right: 65px;
  }
}

.adminInfoDescription {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0 0 40px;
    padding-left: 50px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}
