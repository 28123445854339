@import '../../../styles/propertySets.css';

.root {

}

.rootTitle {
  @apply --marketplaceH2CustomFontStyles;
  border-bottom: 4px  solid var(--marketplaceColor);
  width: 100%;
  max-width: 620px;
  margin: 0 auto 65px;
  padding-bottom: 5px;
  text-align: center;
}

.newsCarouselHolder {
  padding: 0 0 50px;
  
  @media screen and (min-width: 1200px) {
    padding: 0 25px;
  }
  
  & :global(.alice-carousel__prev-btn),
  & :global(.alice-carousel__next-btn) {
      display: inline-block;
      box-sizing: border-box;
      width: auto;
      padding: 15px 10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
  }

  & :global(.alice-carousel__prev-btn) {
      text-align: right;
      left: -15px;
      padding-left: 0;

    @media (--viewportLarge) {
      left: -50px;
    }
  }

  & :global(.alice-carousel__next-btn) {
      right: -15px;
      padding-right: 0;
    @media (--viewportLarge) {
      right: -50px;
    }
  }

  & ul li a {
    display: flex;
    justify-content: center;
  }
}

.newsCarouselImg {
  width: 100%;
  max-width: 200px;
  height: 200px;
  object-fit: contain;
  padding: 10px;
}

.newsCarouselTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--matterColorMiddle);
}

.buttonHolder {

}

.primaryButton {
  color: var(--matterColorLight);
  background: var(--failColor);
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 0;
  height: 56px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 700;

  &:hover {
     background-color: var(--failColorDark);
     color: var(--matterColorLight);
   }
}
