@import '../../../styles/propertySets.css';

.root {

}

.rootTitle {
  @apply --marketplaceH2CustomFontStyles;
  border-bottom: 4px  solid var(--marketplaceColor);
  width: 100%;
  max-width: 260px;
  margin: 0 auto 40px;
  padding-bottom: 5px;
  text-align: center;
}

.rootDescription {
  max-width: 1140px;
  font-size: 20px;
  line-height: 1.4;
  margin: 0 auto 65px;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}


.adminInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column-reverse;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.underline {
  text-decoration: underline;
  font-weight: 400;
}

.adminInfoIcon {
  flex-shrink: 0;
  width: 100%;

  @media (--viewportMedium) {
    width: auto;
  }

  @media (--viewportLarge) {
    margin-right: 65px;
  }
}

.adminInfoDescription {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0 0 40px;
    padding-left: 50px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}


.list {

}

.listItem {
  margin-bottom: 40px;
}

.listItemTitle {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: var(--fontWeightBold);

  & img {
    margin-right: 15px;
  }
}

.listItemDescription {
  max-width: 500px;
  font-size: 18px;
  
  @media (--viewportMedium) {
    font-size: 20px;
  }
}

