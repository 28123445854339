@import '../../../styles/propertySets.css';

.root {

}

.rootTitle {
  @apply --marketplaceH2CustomFontStyles;
  border-bottom: 4px  solid var(--marketplaceColor);
  width: 100%;
  max-width: 205px;
  margin: 0 auto 65px;
  padding-bottom: 5px;
  text-align: center;
}

.toolsList {
  display: flex; 
  justify-content: center;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.toolsItem {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  
  @media (--viewportMedium) {
    width: 25%;
    margin-bottom: 0;
  }
}

.link {
  font-size: 24px;
  color: var(--matterColor);
  font-weight: var(--fontWeightBold);
}

.toolsItemImage {
  height: 105px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 15px;
}