@import '../../../styles/propertySets.css';

.root {
  margin-bottom: 50px;
}

.rootTitleHolder {
  display: flex;
  justify-content: center;
  margin: 0 auto 28px;
  text-align: center;
}

.rootTitle {
  @apply --marketplaceH2CustomFontStyles;
  border-bottom: 4px  solid var(--marketplaceColor);
  display: inline;
}

.textRight {
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
  }
}

.textLeft {
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .textHolder {
      order: 1;
    }

    & .imageHolder {
      order: 2;
    }
  }

}

.imageHolder {
  @media (--viewportMedium) {
    flex: 0 0 51%;
    max-width: 51%;
  }
}

.image {
  max-width: 100%;
  width: 100%;
}

.textHolder {
  @media (--viewportMedium) {
    flex: 0 0 47%;
    max-width: 47%;
  }

}

.textTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.textDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.buttonHolder {
  display: flex;
  padding: 30px 0 30px;
  justify-content: center;
  @media (--viewportMedium) {
    justify-content: flex-start;
  }

}

.primaryButton {
  color: var(--matterColorLight);
  background: var(--failColor);
  height: 56px;
  padding: 0 50px;
  border-radius: var(--borderRadius);
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 300px;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--failColorDark);
    color: var(--matterColorLight);
  }
}
