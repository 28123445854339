@import '../../../styles/propertySets.css';

.root {

}

.rootTitle {
  @apply --marketplaceH2CustomFontStyles;
  border-bottom: 4px  solid var(--marketplaceColor);
  width: 100%;
  max-width: 170px;
  margin: 0 auto 65px;
  padding-bottom: 5px;
  text-align: center;
}

.teachersInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.teachersInfoItem {
  width: 100%;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
  }

  @media (--viewportMLarge) {
    width: 50%;
    max-width: 575px;
    align-items: flex-start;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  & svg {
    min-width: 87px;
    flex-shrink: 0;
    margin-right: 20px;
  }
}

.teachersInfoItem1 {
  @media (--viewportMedium) {
    order: 1;
  }
}

.teachersInfoItem2 {
  @media (--viewportMedium) {
    order: 3;
  }
}

.teachersInfoItem3 {
  @media (--viewportMedium) {
    order: 2;
  }
}

.teachersInfoItem4 {
  @media (--viewportMedium) {
    order: 4;
  }
}

.teachersInfoItem5 {
  display: none;
  @media (--viewportMedium) {
    display: flex;
    order: 5;
  }
}

.teachersInfoItem6 {
  @media (--viewportMedium) {
    order: 6;
  }
}

.teachersInfoItemContent {
  /* display: flex; */
}

.teachersInfoItemTitle {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 6px;
}

.underline {
  text-decoration: underline;
  font-weight: 400;
}

.teachersInfoItemDescription {
  margin: 0;

  @media (--viewportMLarge) {
    max-width: 464px;
  }
}

.referringLink {
  @apply --marketplaceMessageFontStyles;
  margin-top: 24px;
  /*font-size: 14px;*/
  cursor: pointer;
  color: var(--marketplaceColor);

  @media (--viewportLarge) {
    margin-top: 0;
  }

  &:hover,
  &:focus {
     color: var(--marketplaceColorDark);
   }
}

