@import '../../../styles/propertySets.css';

.rootTitleHolder {
  display: flex;
  justify-content: center;
  margin: 0 auto 28px;
  text-align: center;
}

.rootTitle {
  @apply --marketplaceH2CustomFontStyles;
  border-bottom: 4px  solid var(--marketplaceColor);
  display: inline;
}

.rootDescription {
  max-width: 1140px;
  font-size: 20px;
  line-height: 1.4;
  margin: 0 auto 65px;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}


.infoList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.infoItem {
  border: 1px solid #E6E6E6;
  padding: 30px 27px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 6px;

  @media (--viewportMedium) {
    width: calc((100% - 48px)/3);

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.infoItemInactive {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
   }

   &:before {
      content: 'Coming soon';
      color: var(--failColor);
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }
}

.infoImage {
  margin-bottom: 15px;
  text-align: center;
}

.image {
  display: inline-block;
  max-width: 100%;
  width: 90%;
}

.underline {
  border-bottom: 1px solid;
  font-weight: var(--fontWeightRegular);
}

.infoTextTitle {
  /*@apply --marketplaceH2CustomFontStyles;*/
  text-align: center;

  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
  margin-top: 21px;
  margin-bottom: 17px;

  @media (--viewportMedium) {
    line-height: 32px;
    margin-top: 21px;
    margin-bottom: 19px;
  }
}

.infoTextDescription {
  text-align: center;
  font-size: 18px;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.primaryButton {
  color: var(--matterColorLight);
  background: var(--failColor);
  height: 56px;
  padding: 0 50px;
  margin: 20px auto;
  max-width: 320px;
  border-radius: var(--borderRadius);
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 300px;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--failColorDark);
    color: var(--matterColorLight);
  }
}
